/**
 * Тип содержащий ВСЕ существующие коды подсистем
 */
export type SubsystemCode =
  | 'Layout'
  | 'LayoutSections'
  | 'Structure'
  | 'Structure2'
  | 'HVAC'
  | 'Plumbing'
  | 'Electrical'
  | 'Mesh'
  | 'SpacePlanning'
  | 'OMAR'
  | 'OMAR2'
  | 'Final';
