import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {API_URL_PREFIX, HvacConfigDto, HvacGetResourceParamsType, HvacResourceType} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HvacApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getResource(generationVariantId: string, dataType: HvacResourceType): Observable<Blob> {
    const params: HvacGetResourceParamsType = {
      dataType,
    };

    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Hvac/${generationVariantId}/resource`, {
      params: {
        ...params,
      },
      responseType: 'blob',
    });
  }

  getSettings(): Observable<HvacConfigDto> {
    const mockData = {
      typicalStoreyRoomHeatLoss: {
        apartmentFacadeWallHeatTransferCoefficient: 0.335,
        lluFacadeWallHeatTransferCoefficient: 0.335,
        windowHeatTransferCoefficient: 1.524,
        interApartmentDoorHeatTransferCoefficient: 1,
        interRoomDoorHeatTransferCoefficient: 1,
        innerWallHeatTransferCoefficient: 1,
        floorHeatTransferCoefficient: 1,
        ceilingHeatTransferCoefficient: 1,
        ceilingLLUHeatTransferCoefficient: 1,
      },
    };

    return of(mockData);
  }
}
