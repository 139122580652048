import {SubsystemCode} from '../types';

export const SUBSYSTEM_ICONS_MAP: {[subsystemCode in SubsystemCode]: string} = {
  Layout: 'tuiIconTableLarge',
  Structure: 'tuiIconBoxLarge',
  Structure2: 'tuiIconBoxLarge',
  HVAC: 'tuiIconWindLarge',
  Plumbing: 'tuiIconDropletLarge',
  Electrical: 'tuiIconZapLarge',
  SpacePlanning: 'tuiIconLayersLarge',
  OMAR: 'tuiIconCodesandboxLarge',
  OMAR2: 'tuiIconCodesandboxLarge',
};
