import {inject, Injectable} from '@angular/core';
import {RouterNavigation} from '@ngxs/router-plugin';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {PortfolioEntryViewShellState} from '@portfolio/data-access/entry-view-shell';
import {EMPTY, map, Observable, of} from 'rxjs';

import {ProjectEntryViewShellState} from '../entry-view-shell';

export type Breadcrumb = {
  pathFromRoot: string;
  label: Observable<string> | null;
  iconName: string | null;
};

export interface State {
  breadcrumbs: readonly Breadcrumb[];
}

export const defaultState: State = {
  breadcrumbs: [],
};

@Injectable()
@State<State>({
  name: 'projectBreadcrumbs',
  defaults: defaultState,
})
export class ProjectBreadcrumbsState {
  private readonly store = inject(Store);
  private readonly projectSubmodulesNames: {[moduleKey: string]: string} = {
    compare: 'Сравнение вариантов',
    map: 'Карта',
    layout: 'Модуль\u00A0КЗ',
    'layout-sections': 'Модуль\u00A0КЗ',
    spaceplanning: 'Модуль\u00A0ОПР',
    architecture: 'Модуль\u00A0АР',
    'architecture-2': 'Модуль\u00A0АР-2',
    structure: 'Модуль\u00A0КР',
    'structure-2': 'Модуль\u00A0КР-2',
    plumbing: 'Модуль\u00A0ВК',
    hvac: 'Модуль\u00A0ОВиК',
    electrical: 'Модуль\u00A0ЭОМ',
    final: 'Финал',
  };

  @Selector()
  static crumbs(state: State): readonly Breadcrumb[] {
    return state.breadcrumbs;
  }

  @Action([RouterNavigation])
  navigated(ctx: StateContext<State>, payload: RouterNavigation) {
    const [, appModule, portfolioId, projectId, projectSubmodule] = payload.routerState.url
      .replace(/\?.*/, '')
      .split('/');

    if (appModule === 'portfolio') {
      const breadcrumbs: Breadcrumb[] = [
        {
          pathFromRoot: '/portfolio',
          label: null,
          iconName: 'tuiIconBriefcase',
        },
      ];

      if (portfolioId === undefined) {
        ctx.patchState({breadcrumbs});

        return EMPTY;
      }

      breadcrumbs.push({
        pathFromRoot: `/portfolio/${portfolioId}`,
        label: this.store
          .select(PortfolioEntryViewShellState.data)
          .pipe(map(portfolio => portfolio?.name || 'Портфель')),
        iconName: null,
      });

      if (projectId === undefined) {
        ctx.patchState({breadcrumbs});

        return EMPTY;
      }

      breadcrumbs.push({
        pathFromRoot: `/portfolio/${portfolioId}/${projectId}`,
        label: this.store.select(ProjectEntryViewShellState.data).pipe(map(project => project?.name || 'Проект')),
        iconName: null,
      });

      if (projectSubmodule === undefined) {
        ctx.patchState({breadcrumbs});

        return EMPTY;
      }

      breadcrumbs.push({
        pathFromRoot: `/portfolio/${portfolioId}/${projectId}/${projectSubmodule}`,
        label: of(`${this.projectSubmodulesNames[projectSubmodule]}`),
        iconName: null,
      });

      ctx.patchState({breadcrumbs});
    } else {
      ctx.setState({...defaultState});
    }

    return EMPTY;
  }
}
