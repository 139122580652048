import type {PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {tuiStringHashToHsl} from '@taiga-ui/kit';

@Pipe({
  standalone: true,
  name: 'autoColor',
})
export class AutoColorPipe implements PipeTransform {
  transform(text: string): string {
    return tuiStringHashToHsl(text);
  }
}
