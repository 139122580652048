import {SubsystemCode} from '../types';

export const SUBSYSTEM_NAMES_MAP: {[subsystemCode in SubsystemCode]: string} = {
  Layout: 'КЗ',
  Structure: 'КР',
  Structure2: 'КР-2',
  HVAC: 'ОВиК',
  Plumbing: 'ВК',
  Electrical: 'ЭОМ',
  SpacePlanning: 'ОПР',
  OMAR: 'АР',
  OMAR2: 'АР-2',
};
