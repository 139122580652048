import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {stringIsGuid} from '@common/utils/uuidv4';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {entryFormValueToCreateBody, entryFormValueToUpdateBody} from '@project/util';
import {ProjectApiService} from '@projects-api-v2/data-access';
import {ymAction} from '@shared/util';
import {TuiAlertService} from '@taiga-ui/core';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {
  AddImage,
  AddImageFailure,
  AddImageSuccess,
  Create,
  CreateFailure,
  CreateSuccess,
  Update,
  UpdateFailure,
  UpdateSuccess,
} from './actions';

export interface State {
  loading: boolean;
  largeImageError: boolean;
  middleImageError: boolean;
  previewImageError: boolean;
  largeImageName: string | null;
  middleImageName: string | null;
  previewImageName: string | null;
}

export const defaultState: State = {
  loading: false,
  largeImageError: false,
  middleImageError: false,
  previewImageError: false,
  largeImageName: null,
  middleImageName: null,
  previewImageName: null,
};

@Injectable()
@State<State>({
  name: 'projectEntryForm',
  defaults: defaultState,
})
export class ProjectEntryFormState {
  private readonly projectApiService = inject(ProjectApiService);
  private readonly alerts = inject(TuiAlertService);

  @Selector()
  static loading(state: State) {
    return state.loading;
  }

  @Selector()
  static largeImageError(state: State) {
    return state.largeImageError;
  }

  @Selector()
  static middleImageError(state: State) {
    return state.middleImageError;
  }

  @Selector()
  static previewImageError(state: State) {
    return state.previewImageError;
  }

  @Action(Create, {cancelUncompleted: true})
  create(ctx: StateContext<State>, payload: Create) {
    ctx.patchState({loading: true});

    return this.projectApiService.create(entryFormValueToCreateBody(payload.formValue)).pipe(
      switchMap(apiResponse => ctx.dispatch(new CreateSuccess(apiResponse, payload.formValue.description))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new CreateFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new CreateFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(CreateSuccess)
  createSuccess(ctx: StateContext<State>, payload: CreateSuccess) {
    ctx.patchState({loading: false});

    ymAction('ProjectsCreateCreateProject', {id: payload});

    if (stringIsGuid(payload.description)) {
      if (payload.apiResponse === payload.description) {
        return this.alerts.open(`Проект с указанным Id ${payload.apiResponse} успешно создан`, {
          label: 'Выполнено',
          status: 'success',
        });
      }

      return this.alerts.open(`Проект создан с другим id`, {
        label: 'Выполнено',
        status: 'success',
      });
    }

    return this.alerts.open(`Проект успешно создан`, {
      label: 'Выполнено',
      status: 'success',
    });
  }

  @Action(CreateFailure)
  createFailure(ctx: StateContext<State>, payload: CreateFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      status: 'error',
    });
  }

  @Action(Update, {cancelUncompleted: true})
  update(ctx: StateContext<State>, payload: Update) {
    ctx.patchState({loading: true});

    return this.projectApiService.patch(entryFormValueToUpdateBody(payload.formValue), payload.groupId).pipe(
      switchMap(apiResponse => ctx.dispatch(new UpdateSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new UpdateFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new UpdateFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(UpdateSuccess)
  updateSuccess(ctx: StateContext<State>, payload: UpdateSuccess) {
    ctx.patchState({loading: false});

    return this.alerts.open(`Проект «${payload.apiResponse.name}» успешно обновлён`, {
      label: 'Выполнено',
      status: 'success',
    });
  }

  @Action(UpdateFailure)
  updateFailure(ctx: StateContext<State>, payload: UpdateFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      status: 'error',
    });
  }

  @Action(AddImage)
  addImage(ctx: StateContext<State>, payload: AddImage) {
    return this.projectApiService.addImage(payload.image).pipe(
      switchMap(imageName => ctx.dispatch(new AddImageSuccess(imageName, payload.type, payload.image))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(
            new AddImageFailure((error.error as any)?.title || 'Неизвестная ошибка', payload.type, payload.image),
          );
        }

        if (error instanceof Error) {
          return ctx.dispatch(new AddImageFailure(error.message, payload.type, payload.image));
        }

        return EMPTY;
      }),
    );
  }

  @Action(AddImageSuccess)
  addImageSuccess() {
    return this.alerts.open(`Изображение загружено`, {
      label: 'Выполнено',
      status: 'success',
    });
  }

  @Action(AddImageFailure)
  addImageFailure(payload: AddImageFailure) {
    return this.alerts.open(payload.error, {
      label: 'Изображение не загружено',
      status: 'error',
    });
  }
}
