import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {convertBlobError} from '@common/operators';
import {
  API_URL_PREFIX,
  excludeNilProps,
  GenerationVariantCreateBodyDto,
  GenerationVariantDto,
  GenerationVariantsGetParams,
  HttpClientGetOptions,
  SpaceplanningFeatureToggleType,
} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable, of, switchMap, timer} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GenerationVariantsApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  createVariant(
    body: GenerationVariantCreateBodyDto,
    featureToggles?: SpaceplanningFeatureToggleType,
  ): Observable<string> {
    const headers = featureToggles ? {'Content-type': 'application/json', FeatureToggles: featureToggles} : {};

    return this.http.post<string>(`${this.gatewayUrl}/${this.apiPrefix}/GenerationVariants`, body, {
      headers,
    });
  }

  getVariant(id: string): Observable<GenerationVariantDto> {
    return this.http.get<GenerationVariantDto>(`${this.gatewayUrl}/${this.apiPrefix}/GenerationVariants/${id}`);
  }

  getVariants(rawQueryParams?: GenerationVariantsGetParams): Observable<readonly GenerationVariantDto[]> {
    const requestOptions: HttpClientGetOptions = {};

    if (rawQueryParams !== undefined) {
      requestOptions.params = excludeNilProps(rawQueryParams);
    }

    return this.http.get<readonly GenerationVariantDto[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationVariants`,
      requestOptions,
    );
  }

  getObjectModel(variantId: string): Observable<Blob> {
    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/GenerationVariants/${variantId}/objectModel`, {
      responseType: 'blob',
    });
  }

  awaitObjectModelFileGeneration(variantId: string): Observable<Blob> {
    const getRequest$ = (): Observable<Blob> =>
      this.http
        .get(`${this.gatewayUrl}/${this.apiPrefix}/GenerationVariants/${variantId}/objectModel`, {
          observe: 'response',
          responseType: 'blob',
        })
        .pipe(
          switchMap(response => {
            if (response.status === 202 || response.status === 204) {
              return timer(3000).pipe(switchMap(getRequest$));
            }

            return of(response.body);
          }),
          convertBlobError(),
        );

    return getRequest$();
  }
}
