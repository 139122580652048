import {SubsystemCode} from '@shared/util';

export const SUBSYSTEM_ROUTES_MAP: {[subsystemCode in SubsystemCode]: string} = {
  Layout: 'layout',
  Structure: 'structure',
  Structure2: 'structure-2',
  HVAC: 'hvac',
  Plumbing: 'plumbing',
  Electrical: 'electrical',
  SpacePlanning: 'spacePlanning',
  OMAR: 'architecture',
  OMAR2: 'architecture-2',
};
