import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {API_URL_PREFIX, GenerationTaskNodeDto} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GenerationTaskMapApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getTaskMap(projectId: string): Observable<GenerationTaskNodeDto[]> {
    return this.http.post<GenerationTaskNodeDto[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/GenerationTaskMap/${projectId}`,
      {},
    );
  }
}
