import {ChangeDetectionStrategy, Component, HostBinding, inject, Input} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RouterLinkActive} from '@angular/router';
import {SUBSYSTEM_COLORS_MAP, SUBSYSTEM_ICONS_MAP, SUBSYSTEM_NAMES_MAP, SubsystemCode} from '@shared/util';
import {TuiSvgModule} from '@taiga-ui/core';
import {EMPTY} from 'rxjs';

@Component({
  standalone: true,
  imports: [TuiSvgModule],
  selector: 'subsystem-badge, a[subsystemBadge]:not([routerLink]), a[subsystemBadge][routerLink]',
  templateUrl: './subsystem-badge.component.html',
  styleUrl: './subsystem-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubsystemBadgeComponent {
  private readonly routerLinkActive = inject(RouterLinkActive, {optional: true});
  private readonly observer = this.routerLinkActive?.isActiveChange.pipe(takeUntilDestroyed());
  private readonly sub = (this.observer || EMPTY).subscribe(() => {
    this.isActive = this.routerLinkActive.isActive;
  });

  /**
   * В случаях навешивания routerLink на ссылки - директивы нет,
   * поэтому вынимаем её из атрибутов
   */
  @Input('routerLink')
  routerLinkAttr?: Array<string | number>;

  @Input()
  subsystemCode: SubsystemCode | null = null;

  @HostBinding('class._active')
  isActive = false;

  @HostBinding('class._clickable')
  get isClickable(): boolean {
    return !!this.routerLinkAttr;
  }

  @HostBinding('style.border-color')
  get borderColor(): string {
    if (this.subsystemCode === null) {
      return 'var(--tui-neutral-fill)';
    }

    return this.isActive ? `transparent` : `rgb(${SUBSYSTEM_COLORS_MAP[this.subsystemCode]})`;
  }

  @HostBinding('style.background-color')
  get backgroundColor(): string {
    if (this.subsystemCode === null) {
      return 'var(--tui-neutral-bg)';
    }

    return this.isActive
      ? `rgb(${SUBSYSTEM_COLORS_MAP[this.subsystemCode]})`
      : `rgba(${SUBSYSTEM_COLORS_MAP[this.subsystemCode]}, 0.2)`;
  }

  @HostBinding('style.color')
  get color(): string {
    if (this.subsystemCode === null) {
      return 'var(--tui-neutral-fill)';
    }

    return this.isActive ? `#fff` : `rgba(${SUBSYSTEM_COLORS_MAP[this.subsystemCode]})`;
  }

  get subsystemIconName(): string {
    return this.subsystemCode !== null ? SUBSYSTEM_ICONS_MAP[this.subsystemCode] : 'tuiIconMeh';
  }

  get subsystemName(): string {
    return this.subsystemCode !== null ? SUBSYSTEM_NAMES_MAP[this.subsystemCode] : 'ХЗ';
  }
}
