import {SubsystemCode} from '@generation-api-v2/util';

export type ProjectSubsystem = Extract<
  SubsystemCode,
  | 'Layout'
  | 'LayoutSections'
  | 'Structure'
  | 'Structure2'
  | 'HVAC'
  | 'Plumbing'
  | 'Electrical'
  | 'SpacePlanning'
  | 'OMAR'
  | 'OMAR2'
  | 'Final'
>;
